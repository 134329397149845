import { type Market } from "@dotcom/markets";
import { type MouseEvent } from "react";
import {
  Header,
  type HeaderProps,
  NavigationDrawer,
  type NavigationDrawerGroupTypes,
  type HeaderSubNavigationContentProps,
  type MegaMenuProps,
  MegaMenu,
  OfferBar,
  type OfferBarProps,
  ParsedImporterBanner,
  ImporterBannerTop,
  OfferBarVariants,
} from "@polestar/component-warehouse-react";

import { useDeviceFromUrl } from "../hooks";

import LoginButton from "./LoginButton";
import PreviewBanner from "./PreviewBanner";
import { useTracking } from "../TrackingProvider/TrackingProvider";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

interface DrawerItem {
  groupType: NavigationDrawerGroupTypes;
  items: Array<{
    label?: string;
    eventLabel?: string;
    href?: string;
    path?: string;
    groups?: DrawerItem[];
  }>;
}

export interface NavigationProps {
  readonly market?: Market;
  readonly showOfferBarVariant?: boolean;
  readonly waitingForVariant?: boolean;
  readonly isABTest172Market?: boolean;
  readonly onOfferBarCtaClick?: (
    mouseEvent: MouseEvent<HTMLAnchorElement>
  ) => void;
  readonly offerBar?: OfferBarProps;
  readonly megaMenu?: MegaMenuProps;
  readonly drawer?: DrawerItem[];
  readonly dictionary?: {
    close?: string;
    back?: string;
    signin?: string;
  };
  readonly previewConfig?: {
    isPreview: boolean;
    previewApiEndpoint: string;
    projectCode?: string | null;
    defaultProjectCode?: string | null;
  };
  readonly header?: {
    variant?: HeaderProps["variant"];
    subNavigation?: HeaderProps["subNavigation"];
  };
  readonly importerBanner?: ParsedImporterBanner;
}

export const getSubNavigation = (
  subNavigation?: HeaderSubNavigationContentProps[],
  previewConfig?: NavigationProps["previewConfig"]
) => {
  const items = [];

  if (subNavigation) {
    items.push(subNavigation);
  }

  if (
    previewConfig &&
    (previewConfig.isPreview ||
      previewConfig.projectCode !== previewConfig?.defaultProjectCode)
  ) {
    items.push({
      id: "preview-banner",
      node: (
        <PreviewBanner
          isPreview={previewConfig.isPreview}
          previewEndpoint={previewConfig.previewApiEndpoint}
          projectCode={previewConfig.projectCode}
          defaultProjectCode={previewConfig.defaultProjectCode}
        />
      ),
      bleed: false,
    });
  }

  if (items.flat().length > 0) {
    return items.flat();
  }

  return undefined;
};

const OfferBarContainer = styled.div<{
  variant?: OfferBarVariants;
  showOfferBarVariant?: boolean;
  waitingForVariant: boolean;
  isABTest172Market: boolean;
}>(
  ({
    theme,
    variant = OfferBarVariants.default,
    showOfferBarVariant,
    waitingForVariant,
    isABTest172Market,
  }) =>
    /* Below is the styling used for animations of the offer bar when used in A/B tests
     * More information on what this looks like can be found here:
     * https://www.figma.com/proto/mv4pTVPFOrj04hz3GaWYAU/PDP-Improv.---A%2FB-tests?page-id=6603%3A14&node-id=10602-8196&p=f&viewport=63%2C1066%2C0.25&t=vLoRnsMdudrAwT2h-1&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=10602%3A8196&show-proto-sidebar=1
     */
    isABTest172Market
      ? css`
          height: ${showOfferBarVariant || waitingForVariant ? "auto" : "0"};

          background-color: ${theme.offerBar.variants[variant].background};

          > * {
            transition: opacity 0.3s;
            opacity: ${waitingForVariant || !showOfferBarVariant ? "0" : "1"};
          }
        `
      : null
);

const Navigation = ({
  market,
  showOfferBarVariant,
  waitingForVariant = false,
  isABTest172Market = false,
  onOfferBarCtaClick,
  offerBar,
  megaMenu,
  drawer,
  dictionary,
  previewConfig,
  header,
  importerBanner,
}: NavigationProps) => {
  const device = useDeviceFromUrl();
  const { pushToDatalayer } = useTracking();

  const handleLoginButtonClick = () => {
    pushToDatalayer({
      event: "link_click",
      category: "menu",
      action: "category_click",
      label: "sign_in",
    });
  };

  // Don't render any navigation on e.g. space tabletops or space tablets
  if (device) {
    return null;
  }

  const hasMarket = market !== undefined;
  const enableLogin = market?.features.includes("enableLogin");

  return (
    <>
      {importerBanner ? (
        <ImporterBannerTop
          data-testid="dotcom-importer-banner-top"
          message={importerBanner.message}
        />
      ) : null}
      <OfferBarContainer
        variant={offerBar?.variant}
        showOfferBarVariant={showOfferBarVariant}
        waitingForVariant={waitingForVariant}
        isABTest172Market={isABTest172Market}
      >
        {offerBar && (megaMenu || !drawer) ? (
          <OfferBar
            data-testid="dotcom-navigation-offer-bar"
            message={{
              ...offerBar.message,
              ...(onOfferBarCtaClick && {
                link: {
                  ...offerBar.message.link,
                  onClick: (mouseEvent) => {
                    onOfferBarCtaClick(mouseEvent);
                    offerBar.message.link?.onClick?.(mouseEvent);
                  },
                },
              }),
            }}
            variant={offerBar.variant}
            hasMaxWidth={offerBar.hasMaxWidth}
          />
        ) : null}
      </OfferBarContainer>
      {megaMenu ? (
        <MegaMenu
          data-testid="dotcom-navigation-mega-menu"
          skipToContentId="main-content"
          navigation={megaMenu.navigation}
          divider={megaMenu.divider}
          actions={megaMenu.actions}
          variant={megaMenu.variant}
          ariaLabels={megaMenu.ariaLabels}
          subNavigation={getSubNavigation(
            megaMenu?.subNavigation,
            previewConfig
          )}
          logoProps={{
            href: hasMarket ? `/${market?.locale}/` : "/",
          }}
        />
      ) : null}
      {drawer ? (
        <>
          <Header
            data-testid="dotcom-navigation-header"
            logoProps={{
              as: "a",
              href: hasMarket ? `/${market?.locale}/` : "/",
            }}
            menuButtonProps={{
              "aria-label": "menu-toggle-button",
            }}
            offerBarProps={offerBar}
            variant={header?.variant}
            subNavigation={getSubNavigation(
              header?.subNavigation,
              previewConfig
            )}
          />

          <NavigationDrawer
            data-testid="dotcom-navigation-drawer"
            groups={drawer}
            closeButtonProps={{
              "aria-label": dictionary?.close ?? "Close",
            }}
            backButtonProps={{
              "aria-label": dictionary?.back ?? "Back",
            }}
            header={
              hasMarket && enableLogin ? (
                <LoginButton
                  locale={market.locale}
                  buttonText={dictionary?.signin ?? "Sign in"}
                  onClick={handleLoginButtonClick}
                />
              ) : null
            }
          />
        </>
      ) : null}
    </>
  );
};

export default Navigation;
